/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* mat-dropdown style override */
.cdk-overlay-container .cdk-overlay-pane .dropdownpanel {
  margin-top: 7%;
}

.mat-mdc-select-arrow {
  opacity: 0;
}

/* mat-dropdown style override */
.dropdowncheckbox .mat-pseudo-checkbox-checked {
  background-color: #3455e8 !important;
}

.removecheckbox .mat-pseudo-checkbox-checked {
  display: none !important;
}

.custome-dropdown-panel {
  margin-top: 7px !important;
  padding: 8px 0px 8px 0px !important;
  margin-left: -8px !important;
  min-width: 250px !important;
  max-width: 300px !important;
}
.custome-dropdown-panel-nocheck {
  margin-top: 7px !important;
  padding: 8px 0px 8px 0px !important;
  margin-left: -8px !important;
  min-width: fit-content;
}

.custome-search-dropdown-panel {
  margin-top: 7px !important;
  padding: 8px 0px 8px 0px !important;
  margin-left: 8px !important;
  min-width: fit-content;
}

.custome-search-dropdown-panel-nocheck {
  margin-top: 7px !important;
  padding: 8px 0px 8px 0px !important;
  margin-left: 8px !important;
  min-width: fit-content;
}

.mdc-checkbox__ripple {
  display: none;
}

/* mat-radio-button style override */
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-unselected-focus-icon-color: #0278d5 !important;
  --mdc-radio-unselected-hover-icon-color: #0278d5 !important;
  --mdc-radio-unselected-icon-color: #0278d5 !important;
  --mdc-radio-unselected-pressed-icon-color: #0278d5 !important;
  --mdc-radio-selected-focus-icon-color: #0278d5 !important;
  --mdc-radio-selected-hover-icon-color: #0278d5 !important;
  --mdc-radio-unselected-hover-icon-color: #0278d5 !important;
  --mdc-radio-selected-icon-color: #0278d5 !important;
  --mdc-radio-selected-pressed-icon-color: unset !important;
  --mat-mdc-radio-ripple-color: unset !important;
  --mat-mdc-radio-checked-ripple-color: unset !important;
}

.mdc-radio__inner-circle {
  opacity: 0;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-width: 5px !important;
}

// INFO: Use this class to render ngx-editor content properly: NgxEditor__Content
.NgxEditor__Content {
  ul {
    /* reset style */
    list-style: disc;
    padding-left: 1.5em;
  }
  ol {
    /* reset style */
    list-style: decimal;
    padding-left: 1.5em;
  }
  code {
    font-family: monospace;
    background-color: #f1f1f1;
    padding: 2px 4px;
    border-radius: 4px;
  }
  // tailwind based font size
  h1 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.125rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.875rem;
  }
  h5 {
    font-size: 0.75rem;
  }
  h6 {
    font-size: 0.625rem;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
}
